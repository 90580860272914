
import React, { useEffect, useState, useRef } from 'react'
import { css } from '@emotion/css'
import tw, { GlobalStyles } from "twin.macro"


const tailwindStyle = css`
@tailwind base;
@tailwind components;
@tailwind utilities;
`;



const AdStyle = css`

/* .ad-wrapper::before {
    content: "Advertisement";
    display: block;
    height: 1.75rem;
    font-size: .75rem;
    color: #677075;
    text-align: center;
} */

`;

const composedStyle = css`
    ${tailwindStyle};
    ${AdStyle};
`;


interface IAdProps {
    className: string;

}




export const ParagraphAdComponent: React.FC<IAdProps> = ({className, children
}) => {

    const ref = useRef<HTMLModElement>(null);
    const [showLine, setShowLine] = useState(false);

    // https://stackoverflow.com/questions/46035999/how-to-embed-google-adsense-in-react-js
    // https://www.google.com/adsense/new/u/0/pub-7213012588969666/
    // https://github.com/ProdigyAPI/prodigy-wiki/blob/c3256b2632ffceae66bc555d7b6339c3413114d5/src/components/GoogleAdsenseAd.tsx


    useEffect(() => {
        // If there is nothing currently displayed
        if (ref.current != null){
            ref.current.className = "adsbygoogle"
            
            // @ts-expect-error
            if (window.adsbygoogle == null) window.adsbygoogle = [];
            // @ts-expect-error
            window.adsbygoogle.push({});

        }

    }, []);

    return (
        <div className={className} >
            <div className={composedStyle}>
                <GlobalStyles />
                <div className="ad-wrapper" tw="">
                <ins className="adsbygoogle ads-by-google-align"
                    style={{display: 'block', textAlign: 'center'}}
                    data-ad-client="ca-pub-7213012588969666"
                    data-ad-slot="9572773770"
                    data-ad-format="fluid"
                    ref={ref}
                    />
                </div>
            </div>
        </div>
        
    )

}