export const tags = ['Blog']

// TODO - Is article meta going to pull article data as well?
export const siteName = "White Owl Education"

export const blogPost = {
    id: "2021-06-14-00",
    created_at: "2021-06-14T00:00:00.000-00:00",
    updated_at: "2021-06-14T00:00:00.000-00:00",
    page: {
        url: "https://www.whiteowleducation.com/using-logging-in-a-vertex-ai-notebook/"
    },
    author: {
        type: "Person",
        name: "White Owl Education",
        author_image_url: "https://whiteowleducation.com/images/authors/white-owl-education/white-owl-education-author-face.jpg",
        pathname: "images/authors/white-owl-education/white-owl-education-author-face.jpg",
        author_image_description: "Picture of Ralph Brooks, CEO of White Owl Education"
    },
    title: "Tutorial - Generate Logs in Google Cloud with Vertex AI",
    publisher: {
        name: siteName,
        url: "https://whiteowleducation.com/images/theme/header/logo/wo-logo-transparent.png"
    },
    description: "Learn how to push logs from a data science notebook into central logging within Google Cloud.",
    image: {
        url: "https://whiteowleducation.com/images/blog/2021/06/14/card-main-image.jpg",
        description: "Picture of crystals in a fractal pattern."
    },
    internal_featured_image: {
        pathname: "images/blog/2021/06/14/shell-cone.png"
    },
    keywords: tags[0],
    featured: "no",
    read_time_minutes : "4",
    visibility: "public"
}